'use client';

import { MantineProvider } from '@mantine/core';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <MantineProvider
      theme={{
        primaryColor: 'darkGrayTheme',
        colors: {
          darkGrayTheme: [
            '#f1f5f9',
            '#e2e8f0',
            '#cbd5e1',
            '#94a3b8',
            '#64748b',
            '#475569',
            '#334155',
            '#1e293b',
            '#0f172a',
            '#020617'
          ],

          bwTheme: [
            '#e7e7e7',
            '#d1d1d1',
            '#b0b0b0',
            '#888888',
            '#6d6d6d',
            '#5d5d5d',
            '#3d3d3d',
            '#222222',
            '#111111',
            '#000000'
          ]
        },
        primaryShade: 7
      }}
    >
      {children}
    </MantineProvider>
  );
}
